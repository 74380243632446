/*
 * 업무구분: 수익/신탁
 * 파 일 명: FSAlertPopup.vue
 * 파일설명: Alert Modal Popup (공통)
 * 접근권한: 
 * 작 성 일: 2023.02.22
 * 작 성 자: 이태흥
 */
<template>
  
  <!-- alert modal popup -->
  <mo-modal 
    ref="modalPopup"   
    class="fts-modal small" 
    :class="type == 3 || type == 4 || type == 5 ? 'w600' : ''" 
    :title="ptitle">    

    <template v-if="type == 0 || type == 1">
      <span v-if="Array.isArray(content)">
        <span v-for="(row, i) in content" :key="i">
          {{ content[i] }}<br v-if="i < content.length"/>
        </span>
      </span>
      <span v-else>
        {{ content }}
      </span>
    </template>
    <template slot="action" v-if="type == 0">
      <div class="wrap-button button-page-bottom row">
        <mo-button v-if="confirm && cancel" size="large" @click="fn_Close()">취소</mo-button>
        <mo-button v-if="confirm" primary size="large" @click="fn_Confirm()">확인</mo-button>
        <mo-button v-if="!confirm" primary size="large" @click="fn_Close()">확인</mo-button>
      </div>
    </template>
    <template slot="action" v-if="type == 1">
      <div class="wrap-button button-page-bottom row">
        <mo-button v-if="confirm && cancel" size="large" @click="fn_Close()">아니오</mo-button>
        <mo-button v-if="confirm" primary size="large" @click="fn_Confirm()">예</mo-button>
        <mo-button v-if="!confirm" primary size="large" @click="fn_Close()">예</mo-button>
      </div>
    </template>    
    <template v-if="type == 2">
      <div class="column align-center">
        <h2 class="title">고객등록 완료</h2>
        <span class="wrap-text">
          <span v-if="Array.isArray(content)">
            <span v-for="(row, i) in content" :key="i">
              {{ content[i] }}<br v-if="i < content.length"/>
            </span>
          </span>
          <span v-else>
            {{ content }}
          </span>
        </span>
      </div>
    </template>    
    <template slot="action" v-if="type == 2">
      <div class="wrap-button button-page-bottom row">
        <mo-button primary size="large" @click="fn_Close()" v-if="btnType != 1">그룹신규</mo-button>
        <mo-button primary size="large" @click="fn_Close()" v-if="btnType == 1">계좌신규</mo-button>
        <mo-button primary size="large" @click="fn_Confirm()">연금계좌신규개설</mo-button>
      </div>
    </template>

    <template v-if="type == 3">
      <div class="column align-center">
        <h2 class="title">고령투자자 보호안내</h2>
        <span class="wrap-text aL">
          모든 고령 투자자에 대해 '조력자 지정 신청서 및 설명 확인서'를 징구하시기 바랍니다.<br/>
          투자권유 유의상품 권유 시 FP센터장이 '고령투자자에 대한 투자권유 유의상품에 대한 적정성 확인'을 해야 합니다.<br />
          <span v-if="confirm">조력자(만 65세 이상 고객) 등록을 진행합니다.</span>
        </span>
      </div>
    </template>    
    <template slot="action" v-if="type == 3">
      <div class="wrap-button button-page-bottom row">
        <mo-button v-if="confirm" primary size="large" @click="fn_Confirm()">확인</mo-button>
        <mo-button v-if="!confirm" primary size="large" @click="fn_Close()">확인</mo-button>
      </div>
    </template>

    <template v-if="type == 4">
      <div class="column align-center">
        <h2 class="title">금융취약소비자 펀드 가입 제한</h2>
        <span class="wrap-text aL">
          회사 판매 정책에 따라 금융취약소비자(만 80세 이상)의 펀드가입을 제한하고 있습니다.
        </span>
      </div>
    </template>
    <template slot="action" v-if="type == 4">
      <div class="wrap-button button-page-bottom row">
        <mo-button primary size="large" @click="fn_Close()">확인</mo-button>
      </div>
    </template>

    <template v-if="type == 5">
      <div class="column align-center">
        <span class="wrap-text aL">
          FATCA 확인서 추가입력 대상입니다.<br/>
          ERP Portal &gt; 고객정보 &gt; 개인고객정보 &gt; 개인고객등록/조회 &gt; 고객 조회 후<br/>
          왼쪽 하단 FATCA/CRS정보 클릭 후 정보 입력 > 저장 진행해 주시기 바랍니다.<br/>
          이후 신규 진행 가능합니다.
        </span>
      </div>
    </template>
    <template slot="action" v-if="type == 5">
      <div class="wrap-button button-page-bottom row">
        <!-- <mo-button size="large" @click="fn_Close()">취소</mo-button> -->
        <mo-button primary size="large" @click="fn_ConfirmFarca()">확인</mo-button>
      </div>
    </template>

    <template v-if="type == 6">
      <div class="column align-center">
        <h2 class="title" v-if="contentTitle">{{ contentTitle }}</h2>
        <span class="wrap-text aL">
          <span v-if="Array.isArray(content)">
            <span v-for="(row, i) in content" :key="i">
              {{ content[i] }}<br v-if="i < content.length"/>
            </span>
          </span>
          <span v-else>
            {{ content }}
          </span>
        </span>
      </div>
    </template>
    <template slot="action" v-if="type == 6">
      <div class="wrap-button button-page-bottom row" v-if="btnType == 0">
        <mo-button v-if="confirm && cancel" size="large" @click="fn_Close()">취소</mo-button>
        <mo-button primary size="large" @click="fn_Confirm()">확인</mo-button>
      </div>
      <div class="wrap-button button-page-bottom row" v-if="btnType == 1">
        <mo-button v-if="confirm && cancel" size="large" @click="fn_Close()">아니오</mo-button>
        <mo-button primary size="large" @click="fn_Confirm()">예</mo-button>
      </div>      
    </template>

    <template v-if="type == 7">
      <div class="column align-center">
        <h2 class="title" v-if="contentTitle">{{ contentTitle }}</h2>
        <span class="wrap-text">
          <span v-if="Array.isArray(content)">
            <span v-for="(row, i) in content" :key="i">
              {{ content[i] }}<br v-if="i < content.length"/>
            </span>
          </span>
          <span v-else>
            {{ content }}
          </span>
        </span>
      </div>
    </template>
    <template slot="action" v-if="type == 7">
      <div class="wrap-button button-page-bottom row">        
        <mo-button primary size="large" @click="confirm?fn_Confirm():fn_Close()">확인</mo-button>
      </div>
    </template>

    <template v-if="type == 8">
      <div class="column align-center">
        <h2 class="title" v-if="contentTitle">{{ contentTitle }}</h2>
        <span class="wrap-text">
          <span v-if="Array.isArray(content)">
            <span v-if="contentRed" class="colorR">
              {{ contentRed }}<br/>
            </span>
            <span v-for="(row, i) in content" :key="i">
              {{ content[i] }}<br v-if="i < content.length"/>
            </span>            
          </span>          
          <span v-else>
            {{ content }}
          </span>
        </span>
      </div>
    </template>
    <template slot="action" v-if="type == 8">
      <div class="wrap-button button-page-bottom row" v-if="btnType == 0">
        <mo-button v-if="confirm && cancel" size="large" @click="fn_Close()">취소</mo-button>
        <mo-button primary size="large" @click="fn_Confirm()">확인</mo-button>
      </div>
      <div class="wrap-button button-page-bottom row" v-if="btnType == 1">
        <mo-button v-if="confirm && cancel" size="large" @click="fn_Close()">아니오</mo-button>
        <mo-button primary size="large" @click="fn_Confirm()">예</mo-button>
      </div>
      <div class="wrap-button button-page-bottom row" v-if="btnType == 2">
        <mo-button v-if="confirm && cancel" size="large" @click="fn_Close()">취소</mo-button>
        <mo-button primary size="large" @click="fn_Confirm()">이동</mo-button>
      </div>
      <div class="wrap-button button-page-bottom row" v-if="btnType == 3">
        <mo-button v-if="confirm && cancel" size="large" @click="fn_Close()">나가기</mo-button>
        <mo-button primary size="large" @click="fn_Confirm()">진행</mo-button>
      </div>
    </template>

    <template v-if="type == 9">
      <div class="column align-center">
        <h2 class="title" v-if="contentTitle">{{ contentTitle }}</h2>
        <span class="wrap-text">
          <span v-if="Array.isArray(content)">
            <span v-for="(row, i) in content" :key="i">
              {{ content[i] }}<br v-if="i < content.length"/>
            </span>
          </span>          
          <span v-else>
            {{ content }}
          </span>
        </span>
      </div>
    </template>
    <template slot="action" v-if="type == 9">
      <div class="wrap-button button-page-bottom row" v-if="btnType == 0">
        <!-- <mo-button v-if="confirm && cancel" size="large" @click="fn_Close()">취소</mo-button> -->
        <mo-button primary size="large" @click="fn_SelfAthnt()">확인</mo-button>
      </div>
      <div class="wrap-button button-page-bottom row" v-if="btnType == 1">
        <mo-button v-if="confirm && cancel" size="large" @click="fn_Close()">아니오</mo-button>
        <mo-button primary size="large" @click="fn_SelfAthnt()">예</mo-button>
      </div>
      <div class="wrap-button button-page-bottom row" v-if="btnType == 2">
        <mo-button v-if="confirm && cancel" size="large" @click="fn_Close()">취소</mo-button>
        <mo-button primary size="large" @click="fn_SelfAthnt()">이동</mo-button>
      </div>
    </template>
    <template v-if="type === 10">
      <div class="column align-center" >
        <span class="wrap-text" >
          <h2 class="title">▶▶주의사항◀◀</h2>
          하단에 [앱닫기/대기] 팝업노출시<br/>[대기]버튼을 선택해 주십시오.<br/>
          [앱닫기]선택시 전자서명을 다시 진행하니 주의바랍니다.
        </span>
      </div>
    </template>
    <template slot="action" v-if="type == 10">
      <div class="wrap-button button-page-bottom row">
        <!-- <mo-button size="large" @click="fn_Close()">취소</mo-button> -->
        <mo-button primary size="large" @click="fn_Confirm()">확인</mo-button>
      </div>
    </template>

    <!-- popup307 (고객보관서류선택) -->
    <msp-fs-307p
      ref="popup307"
      @fs-popup-callback="fn_Popup_CallBack"
      :popupObj="pPopup307Obj"
      v-if="type == 9"
    ></msp-fs-307p>

    <!-- popup510 (본인인증) -->
    <msp-fs-510p
      ref="popup510"        
      @fs-popup-callback="fn_Popup_CallBack"
      :popupObj="pPopup510Obj"
      v-if="type == 9"
    ></msp-fs-510p>    
  </mo-modal>

</template>
<script>
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  import FSCommUtil from '~/src/ui/fs/comm/FSCommUtil'  
  import MSPFS307P from '~/src/ui/fs/MSPFS307P'  // 고객보관서류선택
  import MSPFS510P from '~/src/ui/fs/MSPFS510P'  // 본인인증  

  export default {
    name: 'AlertPopup',
    screenId: 'MSPFSAlertPopup',
    props: {
      popupObj: {type:Object, default:null},
    },
    components: {      
      'msp-fs-307p': MSPFS307P,
      'msp-fs-510p': MSPFS510P,
    },
    data() {
      return {
        type: 0,
        pPopup307Obj: {}, // 고객보관서류선택 팝업 객체
        pPopup510Obj: {}, // 본인인증 팝업 객체 
        cancel: true,
        confirm: false,
        content: '',
        confirmData: '',
        closeData: '',
        ptitle : '',
        contentTitle: '',
        contentRed: '',
        btnType: 0,
      }
    },
    computed: {
      modalPopup() {
        return this.$refs.modalPopup
      }
    },
    mounted () {
    },
    methods: {
      /******************************************************************************
      * Function명 : fn_Open
      * 설명       : 팝업 오픈 시 초기화
      * 파라미터   : N/A
      ******************************************************************************/
      fn_Open() {
        if( !FSCommUtil.gfn_isNull(this.popupObj) ) {          
          this.ptitle  = this.popupObj.ptitle
          this.confirm = FSCommUtil.gfn_isNull(this.popupObj.confirm) ? false : this.popupObj.confirm
          this.cancel  = FSCommUtil.gfn_isNull(this.popupObj.cancel)  ? true : this.popupObj.cancel          
          this.content = this.popupObj.content
          this.type    = 0
          this.contentTitle = this.popupObj.contentTitle
          this.btnType = 0
          this.contentRed = FSCommUtil.gfn_isNull(this.popupObj.contentRed)  ? '' : this.popupObj.contentRed

          if( !FSCommUtil.gfn_isNull(this.popupObj.closeData) ){
            this.closeData = this.popupObj.closeData
          }
          if( !FSCommUtil.gfn_isNull(this.popupObj.confirmData) ){                      
            this.confirmData = this.popupObj.confirmData
            try {
              if(!FSCommUtil.gfn_isNull(this.confirmData.bfcer_cust_no)) {
                this.$bizUtil.fsUtils.saveCustInfo(this, {bfcer_cust_no: this.confirmData.bfcer_cust_no})
              } else {
                 let custObj = {
                    fnd_cust_nm              : this.confirmData.fnd_cust_nm,
                    rlnm_cnf_no_encr         : this.confirmData.rlnm_cnf_no_encr,
                    bman_celph_dstno_encr    : this.confirmData.bman_celph_dstno_encr,
                    bman_celph_tlnum_no_encr : this.confirmData.bman_celph_tlnum_no_encr,
                    bman_celph_bkno_sno_encr : this.confirmData.bman_celph_bkno_sno_encr,
                    cust_emai_addr_encr      : this.confirmData.cust_emai_addr_encr,
                    cust_emai_dm_nm          : this.confirmData.cust_emai_dm_nm,
                    saveTyp                  : 4
                }
                this.$bizUtil.fsUtils.saveCustInfo(this, custObj)
              }
            } catch (e) {
              // window.vue.error(e)  
            }
          }
          if( !FSCommUtil.gfn_isNull(this.popupObj.type) && this.popupObj.type != 0 ){
            this.type = this.popupObj.type

            if(this.type === 9){
              let msg = '본인인증 후 전자서명을 진행합니다.'
              this.content.push(msg)
            }
          }
          if( !FSCommUtil.gfn_isNull(this.popupObj.btnType) && this.popupObj.btnType != 0 ){
            this.btnType = this.popupObj.btnType
          }
        }
        this.modalPopup.open();
      },

      /******************************************************************************
      * Function명 : fn_Close
      * 설명       : 취소 이벤트
      * 파라미터   : N/A
      ******************************************************************************/
      fn_Close() {
        this.modalPopup.close();
        if( !FSCommUtil.gfn_isNull(this.popupObj.closeFunc) ) {
          this.popupObj.closeFunc.call(this, this.closeData);
        }        
      },

      /******************************************************************************
      * Function명 : fn_Confirm
      * 설명       : 확인 이벤트
      * 파라미터   : N/A
      ******************************************************************************/
      fn_Confirm() {
        this.modalPopup.close();
        if( !FSCommUtil.gfn_isNull(this.popupObj.confirmFunc) ) {
          this.popupObj.confirmFunc.call(this, this.confirmData);
        }
      },

      /******************************************************************************
      * Function명 : fn_ConfirmFarca
      * 설명       : fatca 확인 이벤트
      * 파라미터   : N/A
      ******************************************************************************/
      fn_ConfirmFarca() {
        // 2024.01.17 ASR240100575 FATCA팝업 안닫힘 수정
        this.modalPopup.close()  
        // this.$emit('fs-popup-callback2')
        if( !FSCommUtil.gfn_isNull(this.popupObj.confirmFunc) ) {
          this.popupObj.confirmFunc.call(this, this.confirmData)
        }
      },
      /******************************************************************************
      * Function명 : fn_SelfAthnt
      * 설명       : 본인인증 확인 이벤트
      * 파라미터   : N/A
      ******************************************************************************/
      fn_SelfAthnt() {
        this.modalPopup.close();
        if( !FSCommUtil.gfn_isNull(this.popupObj.confirmFunc) ) {
          this.$refs.popup510.fn_Open('fs')
        }
      },

      /******************************************************************************
      * Function명 : fn_Popup_CallBack
      * 설명       : popup 컴포넌트에서 callback
      * 파라미터   : type(callback type), pData(호출한 페이지 파라미터)
      ******************************************************************************/
      fn_Popup_CallBack(type, pData) {
        switch (type) {
          case 'MSPFS307P': // 고객보관서류 선택
          
            console.log('고객보관서류 선택 callback...', pData)
            if(!FSCommUtil.gfn_isNull(pData)) { // 고객보관서류 선택 시
              this.$bizUtil.fsUtils.saveCustInfo(this, {
                emailYN: pData.emailYN,
                cellPhoneYN:  pData.cellPhoneYN,
                postYN:  pData.postYN,
                saveTyp: 2
              })
              if( !FSCommUtil.gfn_isNull(this.popupObj.confirmFunc) ) {                                
                this.popupObj.confirmFunc.call(this)
              }
            }
            break

          case 'MSPFS510P': // 본인인증
            console.log('본인인증 callback...', pData)
            if(pData) { // 본인인증 정상처리 시
              if( !FSCommUtil.gfn_isNull(this.popupObj.confirmFunc) ) {
                let t_IsCstCstd = this.getStore('fsStore').getters.getState.isCstCstd // 고객보관용 서류 체크 여부
                let docTyp = {}
                if (!t_IsCstCstd) {
                  this.getStore('fsStore').dispatch('IS_CSTCSTD_START')
                  if(this.popupObj.confirmFunc.call(this)) {
                    docTyp = this.$bizUtil.fsUtils.getDocTyp(this)
                    console.log('docTyp : ', docTyp)
                  }
                }

                if(docTyp.isCust) {
                  console.log('고객보관 서류 있음')
                  this.$refs.popup307.fn_Open()
                } else {
                  console.log('고객보관 서류 없음')
                  this.popupObj.confirmFunc.call(this)
                }
              }
            }
            break
        }
      },    

    }
  };
</script>